<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Transformación - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-tabs v-model="tabIndex" align="center" content-class="mt-3">
                <b-tab :disabled="first" title="Salida de Productos" active>
                  <b-row>

                    <!-- <b-col md="2">
                      <b-form-group label="Nro de Transformación :">
                        <b-form-input class="text-center" disabled v-model="transformation.number"></b-form-input>
                      </b-form-group>
                    </b-col> -->

                    
                   

                    <b-col md="2">
                      <b-form-group label="Comprobante :">
                        <b-form-select ref="type_invoice" v-model="transformation.type_invoice_output" :options="invoices_type"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Serie :">
                        <b-form-select ref="type_invoice" v-model="transformation.id_serie_output" :options="series_output"></b-form-select>

                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Numero :">
                        <b-form-input disabled class="text-right" type="text" ref="number" v-model="transformation.number_output"></b-form-input>
                        <small v-if="errors.number" class="form-text text-danger">Ingrese un numero de 8 digitos</small>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Fecha Emision :">
                        <b-form-input type="date" v-model="transformation.broadcast_date"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Almacen :">
                        <b-form-select v-model="transformation.id_warehouse_output" :options="warehouse"></b-form-select>
                        <small v-if="errors.id_warehouse" class="form-text text-danger">Seleccione un almacen</small>
                      </b-form-group>
                    </b-col>

                     <b-col md="2">
                      <b-form-group label=".">
                        <b-button class="form-control btn" variant="primary" @click="modalProducts" ><i class="fas fa-cart-plus"></i> Productos (F2)</b-button>
                      </b-form-group>
                    </b-col>


                    <b-col md="12">
                      <b-form-group label="observación :">
                        <b-form-input v-model="transformation.observation"></b-form-input>
                      </b-form-group>
                    </b-col>

      

                    <!-- Detalle Entrada -->
                    <b-col md="12">
                        <TransformationDetail/>
                        <small v-if="errors.shopping_detail" class="col-md-12 form-text text-center text-danger">Agregue productos</small>
                    </b-col>
                    
            
        
                    <b-col md="5"></b-col>
                    <b-col md="2">
                      <b-form-group class="mt-2">
                        <b-button @click="toNext" variant="info" class="form-control" type="button">SIGUIENTE <i class="fas fa-arrow-circle-right"></i></b-button>
                      </b-form-group>
                    </b-col>
                
                  </b-row>
                </b-tab>
                <b-tab :disabled="second" title="Entrada de Porciones">
                  <b-row>
                    <b-col md="12">
                      <div class="table-responsive mt-3">
                        <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                          <thead>
                            <tr>
                              <th width="3%" class="text-center">#</th>
                              <th width="7%" class="text-center">Codigo</th>
                              <th width="60%" class="text-center">Nombre</th>
                              <th width="10%" class="text-center">U.M.</th>
                              <th width="5%" class="text-center">Cantidad</th>
                              <!-- <th width="5%" class="text-center">Cap.</th>
                              <th width="5%" class="text-center">Cap. Total</th>
                              <th width="5%" class="text-center">Merma</th>
                              <th width="5%" class="text-center">Cap. Final</th>
                              <th width="7%" class="text-center">Cap. Restante</th> -->
                              <th width="5%" class="text-center">Acciones</th>
                            </tr>
                          </thead>
                          <tbody v-for="(item, it) in transformation_detail" :key="it">
                            <tr>
                                <td class="align-middle text-center">{{ it + 1 }}</td>
                                <td class="align-middle text-center">{{ item.code }}</td>
                                <td class="align-middle text-left">{{ item.name  }}</td>
                                <td class="align-middle text-center">{{ item.unit_measure }}</td>
                                <td class="align-middle text-right">{{ item.quantity }}</td>
                                <!-- <td class="align-middle text-right">{{item.capacity}}</td>
                                <td class="align-middle text-right">{{ item.total_capacity }}</td>
                                <td class="align-middle text-right">{{ item.decrease }} </td>
                                <td class="align-middle text-right">{{ item.total_final_capacity }}</td>
                                <td class="align-middle text-right">{{ item.balance_capacity }}</td> -->
                                <td class="align-middle text-center">
                                  <b-button type="button" @click="ViewProductSupplies(it)" variant="primary" ><i class="fas fa-eye"></i></b-button>  
                                </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>

                    <b-col md="12">
                      <div class="table-responsive mt-3">
                        <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                          <thead>
                            <tr>
                              <th width="3%" class="text-center">#</th>
                              <th width="68%" class="text-center">Nombre</th>
                              <th width="10%" class="text-center">U.M.</th>
                              <th width="5%" class="text-center">Porciones</th>
                              <!-- <th width="5%" class="text-center">Cap.</th>
                              <th width="5%" class="text-center">Cap. Total</th> -->
                              <th width="5%" class="text-center">Acciones</th>
                            </tr>
                          </thead>
                          <tbody v-for="(item, it) in transformation_detail_input" :key="it">
                            <tr>
                                <td class="align-middle text-center">{{ it + 1 }}</td>
                                <td class="align-middle text-left">{{ item.name  }}</td>
                                <td class="align-middle text-center">{{ item.unit_measure }}</td>
                                <td class="align-middle text-right">{{ item.quantity }}</td>
                                <!-- <td class="align-middle text-right">{{item.capacity}}</td>
                                <td class="align-middle text-right">{{ item.total_capacity }}</td> -->
                                <td class="align-middle text-center">
                                  <b-button type="button" @click="DeleteProductSupplies(it)" variant="danger" ><i class="fas fa-trash"></i></b-button>  
                                </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                    

                    <b-col md="4"></b-col>
                    <b-col md="2">
                      <b-form-group class="mt-2">
                        <b-button @click="toBack" variant="info" class="form-control" type="button"><i class="fas fa-arrow-alt-circle-left"></i> REGRESAR</b-button>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group class="mt-2">
                        <b-button @click="Validate" variant="primary" class="form-control" type="button"><i class="fas fa-arrow-alt-circle-left"></i> Guardar</b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
            
                </b-tab>
              </b-tabs>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Modal Products -->
    <ModalProducts />
    <ModalProductsSupplies />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="113" @success="modalProducts" />
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");


import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";
// components

import ModalProducts from './components/ModalProduct'
import ModalProductsSupplies from './components/ModalProductSupplies'
import TransformationDetail from './components/TransformationDetail'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      vSelect,
      ModalProducts,
      TransformationDetail,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
      ModalProductsSupplies,
  },
  data() {
    return {
      isLoading:false,
      module: 'Transformation',
      role: 2,
      tabIndex: 0,
      first:false,
      second: true,

      transformation: {
        
        id_transformation:'',
        broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
        observation:'',
        type_operation:'10',
        id_serie_output: '',
        type_invoice_output:'NS',
        serie_output:'',
        number_output:'',
        id_warehouse_output:'',

     

        state:1,
      },

      warehouse:[],
      series_output:[],

      invoices_type:[
        {value: "09", text : "Guía de remisión - Remitente"},
        {value: "NS", text : "Nota de Salida"},
        {value: "GD", text : "Guía de Despacho"},
      ],
      //errors
      errors: {
        number: false,
        broadcast_date: false,
        type_invoice_output: false,
        id_serie_output: false,
        number_output: false,
        id_warehouse_output: false,
        transformation_detail: false,
      },
      validate: false,
    };
  },
  mounted() {
 
    EventBus.$on('CompletePurchase', (linkage) => {
      this.provider = linkage.provider;
      this.transformation.id_warehouse = linkage.id_warehouse;
    });
  
    this.mLoadResetTransformationDetail();
    this.ListWarehouse();
    this.ListSeriesOutput();
  
 
  },
  methods: {
    ListSeriesOutput,
    GetNumberBySerieOutput,
    ListWarehouse,
    toBack,
    toNext,
    modalProducts,

    ViewProductSupplies,
    DeleteProductSupplies,
 
 
    AddTransformation,
    Validate,


    ...mapActions('Transformation',['mLoadResetTransformationDetail']),
    ...mapActions('Transformation',['mLoadDeleteTransformationDetailInput']),
    ...mapActions('Transformation',['mLoadResetTransformationDetailInput']),
    
    
  },

  computed: {
    ...mapState('Transformation',['transformation_detail','transformation_detail_input']),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function toNext() {
  
  this.errors.broadcast_date = this.transformation.broadcast_date == 0 ? true : false;
  this.errors.type_invoice_output = this.transformation.type_invoice_output == 0 ? true : false;
  this.errors.id_serie_output = this.transformation.id_serie_output == 0 ? true : false;
  this.errors.number_output = this.transformation.number_output.length == 0 ? true : false;
  this.errors.id_warehouse_output = this.transformation.id_warehouse_output.length == 0 ? true : false;
  this.errors.transformation_detail = this.transformation_detail.length == 0 ? true : false;
  
  if (this.errors.broadcast_date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.type_invoice_output == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_serie_output == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_warehouse_output == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.transformation_detail == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  this.first = true;
  this.second = false;
  let me = this;
  setTimeout(function(){ me.tabIndex = 2;  }, 500);


}

function toBack() {
  this.mLoadResetTransformationDetailInput();
  this.first = false;
  this.second = true;
  let me = this;
  setTimeout(function(){ me.tabIndex = 1;  }, 500);
}


function ViewProductSupplies(index) {
   let data = {
    role:this.role,
    index:index
  };

  EventBus.$emit('ModalProductsSuppliesShow',data);
}

function DeleteProductSupplies(index) {
  this.mLoadDeleteTransformationDetailInput(index);
}



function ListSeriesOutput() {
  let me = this;
  let url = this.url_base + "serie/list-series-by-code/"+this.transformation.type_invoice_output;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role},
  })
    .then(function (response) {
      me.series_output = [];
      if (response.data.status == 200) {
        let data = response.data.result;
        for (let index = 0; index < data.length; index++) {
          me.series_output.push( { value : data[index].id_serie , text: data[index].serie } );
          me.transformation.id_serie_output = data[index].id_serie;
        }
        if (response.data.result.length == 0)  {
          me.transformation.id_serie_output = '';
          me.transformation.number_output = '';
        }else{
          me.GetNumberBySerieOutput();
        }
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function GetNumberBySerieOutput() {
  let me = this;
  let url = this.url_base + "serie/view/"+this.transformation.id_serie_output;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.transformation.number_output = response.data.result.number;
      } else {
        me.transformation.number_output = '';
      }
    })
}

function modalProducts() {
  let data = {
    role:this.role
  };

  EventBus.$emit('ModalProductsShow',data);
}

function ListWarehouse() {
  let me = this;
  let url = this.url_base + "warehouse/list-active";
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role,},
  })
  .then(function (response) {
    me.warehouse.push({value: '', text: '-- Seleccione un almacen --'});
    if (response.data.status == 200) {
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
        me.warehouse.push({value: element.id_warehouse, text: element.name});
      }
    } else {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    }
  })
}



function AddTransformation() {

  let me = this;
  me.isLoading = true;
  let url = me.url_base + "transformation/add";
  me.transformation.id_user = me.user.id_user;
  me.transformation.transformation_detail = me.transformation_detail;
  me.transformation.transformation_detail_input = me.transformation_detail_input;

  let data = me.transformation;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role,},
  })
    .then(function (response) {
      if (response.data.status == 201) {

 
            me.transformation.id_provider = '';
            me.transformation.id_user = '';
            me.transformation.id_warehouse_output = '';
            me.transformation.broadcast_date = moment(new Date()).local().format("YYYY-MM-DD");
            me.transformation.observation = '';
            
     
            me.transformation.state = 1;
            me.transformation.transformation_detail =  [];
            me.transformation.transformation_detail_input =  [];
            me.mLoadResetTransformationDetail();
            me.ListSeriesOutput();

            me.toBack();

          Swal.fire({ icon: 'success', text: response.data.message,timer: 2000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 2000})
      }
      me.isLoading = false;

    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 2000})
      me.isLoading = false;
    });
}

function Validate() {

  let transformation_detail = this.transformation_detail;
  let transformation_detail_input = this.transformation_detail_input;

  if (transformation_detail.length == 0) {
      Swal.fire({ icon: 'warning', text: 'Ingrese productos ', timer: 2000,}); 
      return false;
  }

   if (transformation_detail_input.length == 0) {
      Swal.fire({ icon: 'warning', text: 'Ingrese las porciones ', timer: 2000,}); 
      return false;
  }
  // let balance = 0;
  // for (let index = 0; index < transformation_detail.length; index++) {
  //   const element = transformation_detail[index];
  //   balance += parseFloat(element.balance_capacity);
  // }

  // if (parseFloat(balance)>0) {
  //   Swal.fire({ icon: 'warning', text: 'Verifique que la capacidad restante esten en 0 de los productos', timer: 2000,}); 
  //   return false;
  // }

  Swal.fire({
    title: 'Esta seguro de registrar la transformación?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      this.AddTransformation();
    }
  })


}


</script>

<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modalProducts" class="w-100" :title="'MODULO DE PORCIONES | ' + name">
      <b-row>
        <b-col md="12">
          <b-form-group label="Buscar producto :">
            <b-form-input type="search" autofocus ref="search_product" v-model="search_product" @keyup="SearchProductsSupplies"></b-form-input>
            
          </b-form-group>
        </b-col>
        <b-col md="12">
          <div class="table-responsive mt-3">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th width="3%" class="text-center align-middle">#</th>
                  <th width="50%" class="text-center align-middle">Nombre</th>
                  <th width="10%" class="text-center align-middle">U. M.</th>
                  <th width="10%" class="text-center align-middle">Porciones</th>
                  <!-- <th width="10%" class="text-center align-middle">Capacidad</th>
                  <th width="7%" class="text-center align-middle">Capacidad Total</th> -->
                  <th width="5%" class="text-center align-middle">Acciones</th>
                </tr>
            
              </thead>
              <tbody v-for="(item, it) in products" :key="it">
                <tr>
                  <td class="text-center">{{ it + 1 }}</td>
                  <td class="text-left">{{ item.name }}</td>
                  <td class="text-left">{{ item.name_unit_measure }}</td>
                  <td class="text-center">
                    <input type="number" @change="EditDetail(it)" step="any" v-model="item.quantity" class="form-control text-right">
                  </td>
                  <!-- <td class="text-center">
                    <input type="number" @change="EditDetail(it)" step="any" v-model="item.capacity" class="form-control text-right">
                  </td> -->
                  <!-- <td class="text-right">{{ item.total_capacity }}</td> -->
                  <td class="text-center">
                      <b-button  type="button" @click="AddProduct(it)" variant="primary"><i class="fas fa-plus-square"></i></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<style>



</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// import Notifications from 'vue-notification/dist/ssr.js';


export default {
  name: "ModalsProduct",
  data() {
    return {
        modalProducts:false,
        module:'Transformation',
        role:0,
        search_product:'',
        name:'',
        products: [],
        index:0,
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalProductsSuppliesShow', (data) => {
      this.modalProducts = true;
      this.index = data.index;
      this.role = data.role;
      this.name = (this.transformation_detail[data.index].name).toUpperCase();
      this.SearchProductsSupplies();
    });
    
  },
  methods: {
      SearchProductsSupplies,
      EditDetail,
      AddProduct,
      ...mapActions('Transformation',['mLoadAddTransformationDetailInput']),
      ...mapActions('Transformation',['mLoadEditTransformationDetailBalance']),
      
  },
  computed: {
    ...mapState('Transformation',['transformation_detail']),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function AddProduct(index) {
    
    let total_capacity = parseFloat(this.products[index].total_capacity);
    let balance_capacity = parseFloat(this.transformation_detail[this.index].balance_capacity);
    // if (total_capacity <= 0) {
    //   this.$notify({ group: 'alert', title: 'Sistema', text:'Ingrese una capacidad', type: 'error'})
    //   return false;
    // }

    // if (total_capacity > balance_capacity) {
    //   this.$notify({ group: 'alert', title: 'Sistema', text:'La capacidad ingresada supera el restante', type: 'error'})
    //   return false;
    // }

    let detail = {
      id_product_supplies: this.products[index].id_product_supplies,
      id_product: this.products[index].id_product,
      code: this.products[index].code,
      name: this.products[index].name,
      unit_measure: this.products[index].unit_measure,
      name_unit_measure: this.products[index].name_unit_measure,
      quantity: parseFloat(this.products[index].quantity).toFixed(2),
      capacity: parseFloat(this.products[index].capacity).toFixed(2),
      total_capacity: parseFloat(this.products[index].total_capacity).toFixed(2),
    }
    this.mLoadAddTransformationDetailInput(detail);
    let data = {
      id_product : detail.id_product,
      total_capacity : detail.total_capacity,
      type : 'Decrease',
    }
    this.mLoadEditTransformationDetailBalance(data);
    this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha agregado producto '+detail.name, type: 'success'})

}
//Buscar productos
function SearchProductsSupplies() {
  let me = this;
  let detail = this.transformation_detail;
  let search = this.search_product == "" ? "all" : this.search_product;
  let url = this.url_base + "product/search-supplies";
  let data = {
    id_product : detail[this.index].id_product,
    search : search,
  };
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {token: this.token, module: this.module, role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.products = response.data.result;
      } else {
        me.products = [];
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      
    });
}

function EditDetail(index) {
  this.products[index].quantity = this.products[index].quantity.length == 0 ? 0:this.products[index].quantity;
  this.products[index].capacity = this.products[index].capacity.length == 0 ? 0:this.products[index].capacity;
  this.products[index].total_capacity = parseFloat(this.products[index].quantity) * parseFloat(this.products[index].capacity);

  this.products[index].quantity = parseFloat(this.products[index].quantity).toFixed(2);
  this.products[index].capacity = parseFloat(this.products[index].capacity).toFixed(2);
  this.products[index].total_capacity = parseFloat(this.products[index].total_capacity).toFixed(2);
}
</script>
